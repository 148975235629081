<template>
  <div class="door-lock-detail">
    <div class="header">
      <div class="lock-overview">
        <i class="iconfont icon-mensuo iconStyle"></i>
        <span class="lock-name">{{ detail.alias }}</span>
        <i class="iconfont icon-dianchi iconStyle"></i>
        <span class="power">{{ detail.power }}%</span>
      </div>
    </div>
    <div class="main">
      <div class="key-manage">
        <div class="title">钥匙管理</div>
        <div class="key-manage-list">
          <div class="key-manage-list-item">
            <div class="key-manage-list-item__title">管理员</div>
            <div class="key-manage-list-item__password">
              <div class="password-header"
                   @click="showPasswordDialog = true">
                <span>密码</span>
                <van-icon class="icon-style"
                          name="add-o"
                          color="@up-primary" />
              </div>
              <div class="password-list"
                   :class="{ showMore: pwdShowMore }">
                <div class="password-list-item"
                     v-for="key in keygroups.keys"
                     :key="key.id">
                  <span class="password-list-item__left">{{ key.alias }}</span>
                  <span class="password-list-item__right"
                        @click="delKey(key.id)">删除</span>
                </div>
                <div class="none-data"
                     v-if="!keygroups.keys.length">暂无数据</div>
              </div>
              <div class="show-more"
                   @click="pwdShowMore = true"
                   v-if="keygroups.keys.length > 1 && !pwdShowMore">
                <van-icon name="arrow-down"
                          style="font-size: 20px" />
              </div>

              <van-popup v-model="showPasswordDialog"
                         class="password-form"
                         @closed="closed"
                         :close-on-click-overlay="false">
                <van-form @submit="onSubmit"
                          @failed="failed"
                          ref="form">
                  <van-popover v-model="showPopover"
                               theme="dark"
                               trigger="click"
                               :actions="actions"
                               @select="onSelect">
                    <template #reference>
                      <van-field v-model="passwordInfo.typeLabel"
                                 name="密码类型"
                                 label="密码类型"
                                 placeholder="用户名"
                                 :rules="[{ required: true, message: '请填写用户名' }]" />
                    </template>
                  </van-popover>
                  <van-field v-model="passwordInfo.password"
                             type="digit"
                             name="密码"
                             label="密码"
                             required
                             placeholder="请输入"
                             :rules="[
                      {
                        required: true,
                        message: '密码不能为空'
                      },
                      {
                        field: 'password',
                        trigger: 'onBlur',
                        validator: validator,
                        message: '密码位数为6-12'
                      }
                    ]" />
                  <van-field v-model="passwordInfo.confirmPassword"
                             type="digit"
                             name="确认密码"
                             label="确认密码"
                             required
                             placeholder="请输入"
                             :rules="[
                      {
                        required: true,
                        message: '确认密码不能为空'
                      },
                      {
                        field: 'confirmPassword',
                        trigger: 'onBlur',
                        validator: validator,
                        message: '两次密码输入不一致'
                      }
                    ]" />
                  <van-field v-model="passwordInfo.startTime"
                             name="开始时间"
                             label="开始时间"
                             placeholder="请输入"
                             required
                             readonly
                             clickable
                             :rules="[{ required: true, message: '开始时间不能为空' }]"
                             @click="dateTimeClick('start')"
                             v-if="passwordInfo.type == 'temporary'" />
                  <van-field v-model="passwordInfo.endTime"
                             name="结束时间"
                             label="结束时间"
                             placeholder="请输入"
                             required
                             readonly
                             clickable
                             :rules="[{ required: true, message: '结束时间不能为空' }]"
                             @click="dateTimeClick('end')"
                             v-if="passwordInfo.type == 'temporary'" />

                  <div class="password-action">
                    <van-button class="password-action-btn"
                                round
                                block
                                type="default"
                                native-type="button"
                                @click="showPasswordDialog = false">取消</van-button>
                    <van-button class="password-action-btn"
                                round
                                block
                                type="info"
                                native-type="submit">确认</van-button>
                  </div>
                </van-form>
              </van-popup>

              <van-popup v-model="showDateTimePicker"
                         position="bottom">
                <van-datetime-picker v-model="currentDate"
                                     type="datetime"
                                     title="选择完整时间"
                                     @confirm="dateTimeConfirm"
                                     @cancel="showDateTimePicker = false" />
              </van-popup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="log-record">
      <div class="title">日志记录</div>
      <div class="log-record-list">
        <div class="log-record-list-item"
             v-for="(log, logIdx) in logList"
             :key="`${log.content}_${logIdx}`">
          <div class="log-record-list-item__left">
            {{ log.content }}
          </div>
          <div class="log-record-list-item__right">
            {{ log.logTime }}
          </div>
        </div>
      </div>
      <van-empty description="暂无数据"
                 v-if="!logList.length" />
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/classroom/doorLockDetail.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";
import { cloneDeep } from "lodash-es";
export default {
  name: "DoorLockDetail",
  data() {
    return {
      detail: {},
      logList: [],
      keygroups: [],
      pwdShowMore: false,

      showPopover: false,
      actions: [
        { text: "永久密码", type: "forever" },
        { text: "临时密码", type: "temporary" }
      ],
      showPasswordDialog: false,
      showDateTimePicker: false,
      currentDate: new Date(),
      passwordInfo: {
        type: "forever",
        typeLabel: "永久密码",
        password: "",
        confirmPassword: "",
        startTime: "",
        endTime: ""
      }
    };
  },
  methods: {
    getLockDetailApi(lockId) {
      let params = {
        requestSource: "NB"
      };
      let placeholder = {
        lockId
      };
      return this.$api.getLockDetail({
        params,
        placeholder
      });
    },
    addNBKeysApi({ keygroupId, key, alias, validFrom = null, validTo = null }) {
      let lockId = this.$route.query.lockId;
      let params = {
        lockId,
        keygroupId,
        keyType: "PASSWORD",
        key,
        alias
      };
      validFrom && (params.validFrom = validFrom);
      validTo && (params.validTo = validTo);
      return this.$api.addNBKeys({
        params
      });
    },
    deleteNBKeysApi(keyId) {
      let lockId = this.$route.query.lockId;
      let params = {
        requestSource: "NB"
      };
      let placeholder = {
        lockId,
        keyId
      };
      return this.$api.deleteNBKeys({
        params,
        placeholder
      });
    },
    getLogRecordsApi(lockId) {
      let params = {
        lockId
      };
      return this.$api.getLogs({
        params
      });
    },
    getLockKeygroupsApi(lockId) {
      let params = {
        requestSource: "NB",
        alias: "管理员"
      };
      let placeholder = {
        lockId
      };
      return this.$api.getLockKeygroups({
        params,
        placeholder
      });
    },
    async getLockDetail() {
      let lockId = this.$route.query.lockId;
      let res = await this.getLockDetailApi(lockId);
      if (res.code != SUCCESS_CODE) return;
      this.detail = res.data;
    },
    async getLockLogRecords() {
      let lockId = this.$route.query.lockId;
      let res = await this.getLogRecordsApi(lockId);
      if (res.code != SUCCESS_CODE) return;
      this.logList = res.data;
    },
    async getLockKeygroups() {
      let lockId = this.$route.query.lockId;
      let res = await this.getLockKeygroupsApi(lockId);
      if (res.code != SUCCESS_CODE) return;
      this.keygroups = res.data;
    },
    async onSubmit() {
      let keygroups = this.keygroups,
        passwordInfo = this.passwordInfo,
        keygroupId = keygroups.id,
        key = passwordInfo.password,
        alias = `***${key.substring(3)}`;

      let params = { keygroupId, key, alias };
      if (passwordInfo.type == "temporary") {
        params.validFrom = passwordInfo.startTime;
        params.validTo = passwordInfo.endTime;
      }
      let res = await this.addNBKeysApi(params);
      if (res.code != SUCCESS_CODE) return;
      this.$dialog
        .alert({
          title: "下发成功",
          message: "下发成功, 请在门锁按9#激活远程联网功能"
        })
        .then(() => {
          // on close
        });
      this.getLockKeygroups();
    },
    delKey(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let res = await this.deleteNBKeysApi(id);
          if (res.code != SUCCESS_CODE) return;
          this.getLockKeygroups();
        })
        .catch(() => {});
    },
    failed(arg) {
      console.log(arg);
    },
    onSelect(arg) {
      this.passwordInfo.type = arg.type;
      this.passwordInfo.typeLabel = arg.text;
    },
    dateTimeClick(type) {
      this.showDateTimePicker = true;
      this.dateTimeType = type;
    },
    dateTimeConfirm(arg) {
      let datetime = this.$moment(arg).format("YYYY-MM-DD HH:mm:ss");
      let types = {
        start: "startTime",
        end: "endTime"
      };
      let type = this.dateTimeType;
      this.passwordInfo[types[type]] = datetime;
      this.showDateTimePicker = false;
    },
    validator(value, rule) {
      console.log(typeof value, rule);
      if (rule.field == "password") {
        return value.length > 12 || value.length < 6 ? false : true;
      } else if (rule.field == "confirmPassword") {
        return value != this.passwordInfo.password ? false : true;
      }
    },
    closed() {
      this.passwordInfo = cloneDeep(this.passwordInfoCopy);
      this.$refs.form.resetValidation();
    },
    init() {
      this.passwordInfoCopy = cloneDeep(this.passwordInfo);
      this.getLockLogRecords();
      this.getLockKeygroups();
      this.getLockDetail();
    }
  },
  created() {
    this.init();
  }
};
</script>
